<template>
  <v-card
    :height="$vuetify.breakpoint.height"
    width="100%"
    class="mx-auto"
    max-width="1000"
    elevation="24"
    tile
  >
    <v-card-text class="pa-0">
      <v-row dense>
        <v-col cols="auto" class="pa-0">
          <v-navigation-drawer
            permanent
            width="200"
            :height="$vuetify.breakpoint.height + 4"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title name">
                  {{ this.myself.full_name }}
                </v-list-item-title>
                <v-list-item-subtitle> online </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense nav>
              <v-list-item
                v-for="item in rooms"
                :key="item.room_name"
                link
                @click="selectChat(item)"
                :class="{
                  green: (selected_chat == item && item.status),
                  'red': !item.status,
                }"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon
                    >mdi-alpha-{{
                      item.name.substring(1, 0).toLowerCase()
                    }}-circle</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="col-10 text-truncate">
                  <v-list-item-title class="text-capitalize">
                    <span>{{ item.name }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="item.unread_count || item.last_typed" class="ma-0">
                  <span class="text-caption" v-if="item.unread_count && !item.last_typed">{{ item.unread_count }}</span>
                  <v-icon v-if="item.last_typed && selected_chat != item">mdi-dots-horizontal</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col class="pa-0">
          <v-card width="100%" flat class="pa-0" v-if="selected_chat">
            <v-card-title class="primary pb-4">
              <span>{{ selected_chat.name }}</span>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                small
                rounded
                @click="deleteRoom"
                v-if="!rooms[selected_index].status"
                >Delete</v-btn
              >
              <v-btn color="red" small rounded @click="exitChat" v-else
                >Exit</v-btn
              >
            </v-card-title>
            <v-card-text
              :style="{ height: $vuetify.breakpoint.height - 165 + 'px' }"
              style="overflow-y: auto"
              class="d-flex flex-column-reverse"
            >
              <span
                class="text-caption ml-2 green--text"
                v-if="rooms[selected_index].last_typed"
                >{{ rooms[selected_index].name }} typing...</span
              >
                <v-card
                  v-for="(message, index) in rooms[selected_index].messages"
                  :key="index"
                  flat
                >
                <v-card v-if="message.not_socket" flat class="mb-2 pa-1 text-truncate">
                  <span :class="[message.class]">{{message.message}}</span>
                </v-card>
                <div v-else>
                <v-list-item
                    v-if="message.attendee_id != myself.attendee_id"
                    class=""
                  >
                    <v-list-item-avatar class="align-self-start mr-2 elevation-1">
                      <v-avatar size="40">
                        <v-img :src="message.attendee_data.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="received-message">
                      <v-card color="success" class="flex-none">
                        <v-card-text class="white--text pa-2 d-flex flex-column">
                          <span class="text-subtitle-1 chat-message">{{
                            message.message
                          }}</span>
                          <span class="text-caption font-italic align-self-end">{{
                            getTime(message.created_at)
                          }}</span>
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else :key="message.msg_id">
                    <v-list-item-content class="sent-message justify-end">
                      <v-card color="primary" class="flex-none">
                        <v-card-text class="white--text pa-2 d-flex flex-column">
                          <span class="text-subtitle-1 chat-message">{{
                            message.message
                          }}</span>
                          <span
                            class="text-caption font-italic align-self-start"
                            >{{ getTime(message.created_at) }}</span
                          >
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                    <v-list-item-avatar class="align-self-start ml-2 elevation-1">
                      <v-img :src="message.attendee_data.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                  </div>
                </v-card>
            </v-card-text>
            <v-card-actions class="pb-0">
              <v-textarea
                placeholder="Type your message"
                rows="2"
                autofocus
                class="px-2"
                filled
                no-resize
                persistent-hint
                hint="Enter to send, Shift+Enter for new line"
                v-model="rooms[selected_index].last_text"
                @keyup="setUserTyping"
                @keydown.enter.exact.prevent
                @keyup.enter.exact="sendMessage()"
                @keydown.enter.shift.exact="newlineMessage"
                :disabled="!rooms[selected_index].status"
              ></v-textarea>
            </v-card-actions>
          </v-card>
          <v-card
            v-else
            flat
            :height="$vuetify.breakpoint.height"
            class="d-flex align-center"
          >
            <v-card-text class="text-center">
              <span class="text-h5">Messages will appear here</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn
      elevation="2"
      color="primary"
      small
      dark
      fab
      fixed
      bottom
      left
      @click="switchThemeMode"
    >
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import WsSubscriptions from "../../helper/websocket";
import axios from "@/helper/axios";
export default {
  data() {
    return {
      rooms: [],
      selected_chat: null
    };
  },
  computed: {
    myself() {
      return JSON.parse(localStorage.getItem("churchil_user"));
    },
    currentRoomName() {
      return this.rooms[this.selected_index].room_name;
    },
    selected_index(){
      return this.rooms.indexOf(this.selected_chat);
    }
  },
  methods: {
    subscribeToIncoming() {
      let subscription = this.$ws.socket.getSubscription(
        `user:${this.myself.attendee_id}`
      );
      if (!subscription) {
        subscription = this.$ws.subscribe(`user:${this.myself.attendee_id}`);
        this.$ws.$on(
          `user:${this.myself.attendee_id}|new_request`,
          this.setNewRoom
        );
      }
      //connect to helper room to maintain online/ offline status
      let helperSubscription = this.$ws.socket.getSubscription('helper');
      if(!helperSubscription){
        this.$ws.subscribe('helper');
      }
    },
    switchThemeMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    setNewRoom(socketData) {
      this.rooms.unshift({
        name: socketData.attendee_data.full_name,
        room_name: socketData.room_name,
        unread_count: 0,
        messages: [],
        last_text: "",
        status: true,
        last_typed: null,
      });
      this.joinUserRoom(socketData.room_name);
    },
    joinUserRoom(roomName) {
      let subscription = this.$ws.socket.getSubscription(
        `infodesk:${roomName}`
      );
      if (!subscription) {
        subscription = this.$ws.subscribe(`infodesk:${roomName}`);
        this.$ws.$on(`infodesk:${roomName}|new_message`, this.setSocketMessage);
        this.$ws.$on(`infodesk:${roomName}|room_closed`, this.setRoomClosed);
        this.$ws.$on(`infodesk:${roomName}|user_typing`, this.setHelperTyping);
        subscription.emit("helperConnected", roomName);
      }
    },
    setRoomClosed(roomName) {
      this.rooms = this.rooms.map((room) => {
        if (room.room_name == roomName) {
          room.status = false;
          room.messages.unshift({
            message: "room# is closed",
            class: "red--text",
            not_socket : true
          });
        }
        return room;
      });
      let subscription = this.$ws.socket.getSubscription(
        `infodesk:${roomName}`
      );
      if (subscription) {
        subscription.close();
        this.$ws.socket.removeSubscription(`infodesk:${roomName}`);
      }
      // if(this.rooms[this.selected_index].room_name == roomName){
      //   this.selected_index = null;
      //   this.selected_chat = null;
      // }
    },
    setSocketMessage(socketData) {
      this.rooms = this.rooms.map((room) => {
        if (room.room_name == socketData.room_name) {
          room.messages.unshift(socketData);
        }
        return room;
      });
      if(this.selected_index >= 0){
        if (this.currentRoomName != socketData.room_name) {
           this.rooms = this.rooms.map((room) => {
              if (room.room_name == socketData.room_name) {
                room.unread_count++
              }
              return room;
          });
        }
      } else {
        console.log('rooooom else')
        this.rooms = this.rooms.map((room) => {
          if (room.room_name == socketData.room_name) {
            room.unread_count++
          }
          return room;
        });
      }
    },
    setHelperTyping(socketData) {
      if (socketData.typed_by != this.myself.attendee_id) {
        this.rooms = this.rooms.map((room) => {
          if (room.room_name == socketData.room_name) {
            room.last_typed = new Date();
          }
          return room;
        });
        let interval = setInterval(() => {
          this.rooms = this.rooms.map((room) => {
            if (
              room.room_name == socketData.room_name &&
              Date.now() - room.last_typed >= 1500
            ) {
              room.last_typed = null;
            }
            return room;
          });
          clearInterval(interval);
        }, 1500);
      }
    },
    setUserTyping() {
      if (event.key !== "Enter") {
        let subscription = this.$ws.socket.getSubscription(
          `infodesk:${this.currentRoomName}`
        );
        subscription.emit("userTyping", {
          typed_by: this.myself.attendee_id,
          room_name: this.currentRoomName,
        });
      }
    },
    selectChat(room) {
      this.selected_chat = room;
      this.rooms[this.selected_index].unread_count = 0;
    },
    exitChat() {
      this.setRoomClosed(this.currentRoomName);
    },
    deleteRoom() {
      this.rooms = this.rooms.filter((room) => {
        return room.room_name != this.currentRoomName;
      });
      this.selected_chat = null;
    },
    newlineMessage() {
      this.selected_chat.last_text = `${this.selected_chat.last_text}`;
    },
    sendMessage() {
      if (this.selected_chat.last_text) {
        axios.post("infodesk/add_message", {
          message: this.selected_chat.last_text,
          room_id: this.rooms[this.selected_index].room_name,
        });
      }
      this.selected_chat.last_text = "";
    },
    getTime(time) {
      let msg_date = this.moment(time);
      let today = this.moment(new Date());
      if (msg_date.isSame(today, "day")) {
        return this.moment(time).format("hh:mm A");
      } else {
        return msg_date.format("DD, MMM hh:mm A");
      }
    },
  },
  async created() {
    await WsSubscriptions(this.myself.attendee_token);
  },
  mounted: function () {
    this.$vuetify.theme.dark = true;
    this.subscribeToIncoming();
  },
};
</script>

<style scoped>
html {
  overflow-y: auto;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #6a4ba2 transparent transparent transparent;
}
</style>